
import React, {useState} from 'react';

const styles={
    container:{
        height:'100vh',
        position:'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',   
        backgroundColor:'',
        color:'black',
        opacity:'0.4',
        fontSize:32
    }    
  }


export default () => <div style={styles.container}>
    <h2>Page not found</h2>
</div>


